import React from "react"
import {Formik, Form, Field, ErrorMessage} from "formik"
import * as Yup from "yup";
import {loadStripe} from "@stripe/stripe-js";
import styled from "styled-components";
import SignUpConfirmation from "../molecules/SignUpConfirmation";

const Holder = styled.div`
  legend {
    margin-top: 4rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin-top: 8rem;
    }
  }

  .fieldset.radio {
    max-width: 40rem;
    label { margin-bottom: 1rem; }
  }
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    .fieldset.email {
      grid-column: span 2;
    }
  }
  .fieldset { margin: 0; }
  input {
    max-width: none;
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("* First name is Required."),
  lastName: Yup.string().required("* Last name is Required."),
  petName: Yup.string().required("* Pat name is Required."),
  petType: Yup.string().required("* Pet type is Required."),
  email: Yup.string()
    .email("* Enter a Valid Email.")
    .required("* Email is Required."),
  petAge: Yup.string().required("* Pet age is Required."),
  furColour: Yup.string().required("* Fur colour is Required."),
  furType: Yup.string().required("* Fur type is Required."),
  training: Yup.string().required("* Training is Required."),
  subscriptionType: Yup.string().required("* Subscription type is Required."),
})

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`)
  }
  return stripePromise
}

function SignUpForm() {

  const redirectToCheckout = async (priceId) => {
    const stripe = await getStripe()
    const {error} = await stripe.redirectToCheckout({
      mode: 'subscription',
      lineItems: [{price: priceId, quantity: 1}],
      successUrl: `${process.env.GATSBY_STRIPE_SUCCESS_URL}`,
      cancelUrl: `${process.env.GATSBY_STRIPE_CANCEL_URL}`,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }

  return (
    <Holder>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          petName: "",
          petType: "",
          email: "",
          petAge: "",
          furColour: "",
          furType: "",
          training: "",
          subscriptionType: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting}) => {
          console.log('submitted');
          fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
              "form-name": "sign-up-form",
              ...values,
            }),
          })
            .then(() => redirectToCheckout(values.subscriptionType))
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
            })
        }}
      >
        {({values, isSubmitting, dirty, isValid}) => (
          <Form
            className="form"
            method="post"
            name="sign-up-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >

            <Intro>
              <div className="fieldset input">
                <label htmlFor="firstName" className="label">First Name</label>
                <Field className="input" type="text" name="firstName" placeholder="First Name" />
                <ErrorMessage className="errorMsg" name="firstName" component="small" />
              </div>

              <div className="fieldset input">
                <label htmlFor="lastName" className="label">Last Name</label>
                <Field className="input" type="text" name="lastName" placeholder="Last Name" />
                <ErrorMessage className="errorMsg" name="lastName" component="small" />
              </div>

              <div className="fieldset input">
                <label htmlFor="petName" className="label">Pet Name</label>
                <Field className="input" type="text" name="petName" placeholder="Pet Name" />
                <ErrorMessage className="errorMsg" name="petName" component="small" />
              </div>

              <div className="fieldset select">
                <label htmlFor="petType" className="label">Pet Type</label>
                <Field as="select" name="petType">
                  <option value="" disabled>Pet Type</option>
                  <option value="dog">Dog</option>
                  <option value="cat">Cat</option>
                </Field>
                <ErrorMessage className="errorMsg" name="petType" component="small" />
              </div>

              <div className="fieldset input email">
                <label htmlFor="email" className="label">Email</label>
                <Field className="input" type="email" name="email" placeholder="Email Address" />
                <ErrorMessage className="errorMsg" name="email" component="small" />
              </div>
            </Intro>

            <legend className="h2">Welcome{values.firstName ? `, ${values.firstName}. A` : ', a'} few quick questions about your pet to get started — how old is {values.petName || 'your pet'}?</legend>
            <div className="fieldset radio">
              <div role="group" aria-labelledby="pet-age">
                <label><Field type="radio" name="petAge" value="Baby" /><span className="checkmark"/><span className="text">{values.petType === 'cat' ? 'Kitten' : 'Puppy'}</span></label>
                <label><Field type="radio" name="petAge" value="Junior" /><span className="checkmark"/><span className="text">Junior</span></label>
                <label><Field type="radio" name="petAge" value="Adult" /><span className="checkmark"/><span className="text">Adult</span></label>
                <label><Field type="radio" name="petAge" value="Senior" /><span className="checkmark"/><span className="text">Senior</span></label>
              </div>
              <ErrorMessage className="errorMsg" name="petAge" component="small" />
            </div>

            <legend className="h2">What is the colour of {values.petName || 'your pet'}’s fur?</legend>
            <div className="fieldset radio">
              <div role="group" aria-labelledby="fur-colour">
                <label><Field type="radio" name="furColour" value="White" /><span className="checkmark"/><span className="text">White</span></label>
                <label><Field type="radio" name="furColour" value="Tan" /><span className="checkmark"/><span className="text">Tan</span></label>
                <label><Field type="radio" name="furColour" value="Black" /><span className="checkmark"/><span className="text">Black</span></label>
                <label><Field type="radio" name="furColour" value="Light Brown" /><span className="checkmark"/><span className="text">Light Brown</span></label>
                <label><Field type="radio" name="furColour" value="Dark Brown" /><span className="checkmark"/><span className="text">Dark Brown</span></label>
                <label><Field type="radio" name="furColour" value="Cream" /><span className="checkmark"/><span className="text">Cream</span></label>
                <label><Field type="radio" name="furColour" value="Red" /><span className="checkmark"/><span className="text">Red</span></label>
                <label><Field type="radio" name="furColour" value="Grey" /><span className="checkmark"/><span className="text">Grey</span></label>
                <label><Field type="radio" name="furColour" value="Other" /><span className="checkmark"/><span className="text">Other</span></label>
              </div>
              <ErrorMessage className="errorMsg" name="furColour" component="small" />
            </div>


            <legend className="h2">How long is {values.petName || 'your pet'}’s fur?</legend>
            <div className="fieldset radio">
              <div role="group" aria-labelledby="fur-type">
                <label><Field type="radio" name="furType" value="Short" /><span className="checkmark"/><span className="text">Short</span></label>
                <label><Field type="radio" name="furType" value="Medium" /><span className="checkmark"/><span className="text">Medium</span></label>
                <label><Field type="radio" name="furType" value="Long" /><span className="checkmark"/><span className="text">Long</span></label>
                <label><Field type="radio" name="furType" value="Combination" /><span className="checkmark"/><span className="text">Combination</span></label>
              </div>
              <ErrorMessage className="errorMsg" name="furType" component="small" />
            </div>

            <legend className="h2">Does {values.petName || 'your pet'} have any training?</legend>
            <div className="fieldset radio">
              <div role="group" aria-labelledby="training">
                <label><Field type="radio" name="training" value="None" /><span className="checkmark"/><span className="text">None</span></label>
                <label><Field type="radio" name="training" value="House Trained" /><span className="checkmark"/><span className="text">House Trained</span></label>
                <label><Field type="radio" name="training" value="Basic Obedience" /><span className="checkmark"/><span className="text">Basic Obedience</span></label>
                <label><Field type="radio" name="training" value="High Obedience" /><span className="checkmark"/><span className="text">High Obedience</span></label>
                <label><Field type="radio" name="training" value="Special Abilities" /><span className="checkmark"/><span className="text">Special Abilities</span></label>
              </div>
              <ErrorMessage className="errorMsg" name="training" component="small" />
            </div>

            <SignUpConfirmation values={values} isSubmitting={isSubmitting} dirty={dirty} isValid={isValid}>
              <div className="fieldset radio serif">
                <div role="group" aria-labelledby="subscription-type">
                  <label><Field type="radio" name="subscriptionType" value="price_1JXyTDFekBYuf7lnUur2C3rh" /><span className="checkmark"/><span className="text">Monthly<br/>$15.95</span></label>
                  <label><Field type="radio" name="subscriptionType" value="price_1JXySsFekBYuf7lnejCSEx6B" /><span className="checkmark"/><span className="text">Yearly<br/>$150</span></label>
                </div>
                <ErrorMessage className="errorMsg" name="subscriptionType" component="small" />
              </div>
            </SignUpConfirmation>

          </Form>
        )}
      </Formik>
    </Holder>
  )
}

export default SignUpForm
