import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.grey};
  color:  ${props => props.theme.colours.black};
  overflow: hidden;
  border-radius: 1.5rem;
  .name {
    margin: 4rem 1rem 0 1rem;
  }
  ul {
    margin: 2rem 0 0 0;
    padding: 0;
    list-style: none;
    li {
      border-bottom: 1px solid;
      padding: 0.5rem 1rem;
      display: grid;
      grid-template-columns: 6rem 2fr;
      grid-gap: 1rem;
      &:first-child {
        border-top: 1px solid;
      }
    }
  }
  input[type="submit"] {
    border: none;
    border-radius: 0;
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
    &:hover {
      background-color: rgba(0,0,0,0.9);
    }
  }
`;

const Pip = styled.p`
  background-color: ${props => props.theme.colours.purple};
  color:  ${props => props.theme.colours.black};
  line-height: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 1rem;
  ${props => props.theme.fluidType( -1 )};
  display: inline-block;
  margin: 1rem 0 0 1rem;
  text-transform: capitalize;
`;

function PetConfirmationCard({values, isSubmitting, dirty, isValid}) {
  return (
    <Holder className="confirmation-card">
      <Pip>{values.petType}</Pip>
      <h4 className="h2 name">{values.petName}</h4>
      <ul>
        <li><span>Age: </span><span className="value">{values.petAge}</span></li>
        <li><span>Colour: </span><span className="value">{values.furColour}</span></li>
        <li><span>Fur type: </span><span className="value">{values.furType}</span></li>
        <li><span>Training: </span><span className="value">{values.training}</span></li>
      </ul>
      <input
        name="submit"
        type="submit"
        disabled={isSubmitting}
        value="Confirm"
        className="button inverse"
      />
    </Holder>
  )
}

PetConfirmationCard.propTypes = {
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default PetConfirmationCard;