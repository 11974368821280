import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import PetConfirmationCard from "./PetConfirmationCard";

const Holder = styled.div`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 3rem;
  }
  &.show {
    .missing-fields { display: none; }
    .text-holder,
    .confirmation-card { display: block; }
  }
  &.hide {
    .missing-fields { display: block; }
    .text-holder,
    .confirmation-card { display: none; }
  }
`;

const Text = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  .fieldset.radio {
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      margin-top: 2rem;
    }
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      padding: 4rem 1rem 1.5rem 1rem;
      margin: 0;
      line-height: 1.2;
      border-radius: 1rem;
      width: 100%;
      background-color: ${props => props.theme.colours.grey};
      /* Hide the browser's default radio button */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        z-index: 1;
        top: 1rem;
        left: 1rem;
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid ${props => props.theme.colours.black};
        border-radius: 50%;
      }
      .text {
        position: relative;
        z-index: 2;
        ${(props) => props.theme.fluidType(2)};
      }
      /* On mouse-over, add a background color */
      &:hover input ~ .checkmark {
        background-color: ${props => props.theme.colours.purple2};
      }
      /* When the radio button is checked, add a background */
      input:checked ~ .checkmark {
        background-color: ${props => props.theme.colours.purple};
      }
      input:checked ~ .checkmark ~ .text {
        color: inherit;
      }
  }
`;

function SignUpConfirmation({children, values, isSubmitting, dirty, isValid}) {
  const [ show, setShow ] = useState( false );

  useEffect(() => {
    if (
      !values.firstName ||
      !values.firstName ||
      !values.lastName ||
      !values.petName ||
      !values.petType ||
      !values.email ||
      !values.petAge ||
      !values.furColour ||
      !values.furType ||
      !values.training
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [values]);



  return (
    <Holder className={show ? 'show': 'hide'}>
      <p className="missing-fields h3">It seem you are still missing some fields. Fill these out to continue.</p>
      <Text className="text-holder">
        <p className="h3">Thanks, {values.firstName} — {values.petName} is set up, and we're almost done.</p>
        <p>Double-check your details, and then click Confirm to head to check-out.</p>
        {children}
      </Text>
      <PetConfirmationCard isSubmitting={isSubmitting} values={values} dirty={dirty} isValid={isValid} />
    </Holder>
  )
}

SignUpConfirmation.propTypes = {
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default SignUpConfirmation;