import React from 'react';
import styled from 'styled-components';
import SEO from "../components/molecules/SEO";
import Container from "../components/atoms/Container";
import SignUpForm from "../components/organisms/SignUpForm";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.purple2};
  color: ${props => props.theme.colours.black};
  padding: 4rem 0;
`;

const Inner = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 10rem calc(100 / 12 * 1% + 2rem);
  }
`;

function SignUpPage() {
  return (
    <>
      <SEO title="Create an account" />
      <Holder>
        <Container>
          <Inner>
            <h1 className="h2">Create an account</h1>
            <SignUpForm/>
          </Inner>
        </Container>
      </Holder>
    </>
  )
}

export default SignUpPage;